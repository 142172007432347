export const SHOPPING_CART_LIST_TABLE = {
  tableName: "ShoppingCartListTable",
  columns: [
    {
      label: "操作",
      prop: "control",
      width: 50,
      align: "center",
      fixed: "left"
    },
    {
      label: "数量",
      prop: "qty",
      width: 140,
      attrs: {
        sortable: "custom"
      }
    },
    {
      label: "商品编码",
      prop: ["goods", "code"],
      width: 120,
      attrs: {
        sortable: "custom"
      }
    },
    {
      label: "商品名称",
      prop: ["goods", "name"],
      width: 280
    },
    {
      label: "车型",
      prop: ["goods", "vehModel"],
      width: 150
    },
    {
      label: "品牌",
      prop: ["goods", "brand"],
      width: 100
    },
    {
      label: "产地",
      prop: ["goods", "productionPlace"],
      width: 100
    },
    {
      label: "购买参数",
      prop: "buyParams",
      width: 150
    },
    {
      label: "库存",
      prop: ["goods", "qty"],
      width: 100,
      attrs: {
        sortable: "custom"
      }
    },
    {
      label: "单价",
      prop: ["goods", "price"],
      width: 100,
      attrs: {
        sortable: "custom"
      }
    },
    {
      label: "小计",
      prop: "total",
      width: 100,
      attrs: {
        sortable: "custom"
      }
    },
    // {
    //   label: "仓库",
    //   prop: "warehouse",
    //   width: 100
    // },
    // {
    //   label: "生产批号",
    //   prop: "productBatch",
    //   width: 100
    // },
    {
      label: "商品分类",
      prop: ["goods", "category"],
      width: 150
    },
    {
      label: "商品性质",
      prop: ["goods", "property"],
      width: 80
    },
    {
      label: "品质",
      prop: ["goods", "insurCertType"],
      width: 100
    },
    {
      label: "规格",
      prop: ["goods", "model"],
      width: 80
    },
    {
      label: "生产码",
      prop: ["goods", "productionCode"],
      width: 100
    },
    {
      label: "添加人",
      prop: "createdByName",
      width: 100
    },
    {
      label: "加入时间",
      prop: "createdAt",
      width: 140,
      attrs: {
        sortable: "custom"
      }
    }
  ]
};
